import React, { Component } from 'react';
import { URL_GET_COUNT } from '../constans';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { setCount } from './../actions/dashboard';
import { getCount, getAvatar, getAvatarAccessory } from './../selectors/dashboard';
import DashboardComponent from '../components/DashboardComponent';

class DashboardContainer extends Component {

  componentDidMount(){
    this.getCount();
  }

  handleChangeAvatar = () => {
    this.props.history.push('/avatar');
  }

  handleGoShop = () => {
    this.props.history.push('/store');
  }

  getCount(){
    axios({
      url: URL_GET_COUNT,
      method: "GET",
      headers:{
        'x-auth-token': this.props.token,
        'Content-Type': 'application/json'
      }
    })
    .then( (res) => {
      this.props.setCount(res.data);
    })
    .catch( err => {
      return Promise.reject(err);
    })
  }

  render() {
    return (
      <DashboardComponent 
        name={"Arturo"}
        count={this.props.count}
        handleChangeAvatar={this.handleChangeAvatar}
        handleGoShop={this.handleGoShop}
        avatar={this.props.avatar}
        avatarAccessory={this.props.avatarAccessory}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  count: getCount(state),
  avatar: getAvatar(state),
  avatarAccessory: getAvatarAccessory(state)
});

export default withRouter( connect( mapStateToProps, { setCount
}) (DashboardContainer) );