import React, { Component } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { setAvatar } from '../actions/avatar';
import { withRouter } from 'react-router-dom';
import { URL_GET_COUNT } from '../constans';
import { getCount, getAvatar, getAvatarAccessory, getAvatars } from '../selectors/dashboard';
import AvatarComponent from '../components/AvatarComponent';

class AvatarContainer extends Component {
  componentDidMount(){
    //this.getAvatar();
  }

  getAvatar(){
    Axios({
      url: URL_GET_COUNT,
      method: "GET",
      headers:{
        'x-auth-token': this.props.token,
        'Content-Type': 'application/json'
      }
    })
    .then( (res) => {
      this.props.setAvatar(res.data);
    })
    .catch( err => {
      return Promise.reject(err);
    })
  }

  handleChangeAvatar = ( value ) => {
    console.log(value);
    this.props.setAvatar(value);
    this.props.history.push('/home');
  }

  render(){
    return (
      <AvatarComponent 
        name={"Arturo"}
        avatars={this.props.avatars}
        avatarAccessory={this.props.avatarAccessory}
        handleChangeAvatar={this.handleChangeAvatar}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  count: getCount(state),
  avatar: getAvatar(state),
  avatars: getAvatars(state),
  avatarAccessory: getAvatarAccessory(state)
});

export default ( 
  withRouter( 
    connect( mapStateToProps, { setAvatar } 
      ) (AvatarContainer) 
  )
);