import {handleActions} from 'redux-actions';

import { SET_AVATAR, SET_AVATAR_ACCESSORY, SET_AVATARS } from './../constans';

export const avatar = handleActions({
  [SET_AVATAR]: (state, action) => {
    return action.payload;
  }
},
"Uku")

export const avatarAccessory = handleActions({
  [SET_AVATAR_ACCESSORY]: (state, action) => {
    
    console.log(state);
    var data = state;
    if(action.payload.category == "body"){
      data.body = action.payload.name;
    }
    if(action.payload.category == "jewelry"){
      data.jewelry = action.payload.name;
    }
    if(action.payload.category == "head"){
      data.head = action.payload.name;
    }
    if(action.payload.category == "body_bottom"){
      data.body_bottom = action.payload.name;
    }
    if(action.payload.category == "footer"){
      data.footer = action.payload.name;
    }
    console.log(data);
    return data;
  }
},{
  "jewelry":"joyeriaAzul",
  "head":"pañueloCuello",
  "body":"camisaPlatano",
  "body_bottom":"short",
  "footer":"tenis"
});

export const avatars = handleActions({
  [SET_AVATARS]: (state, action) => {
    return action.payload;
  }
},[
  "Uku",    
  "verde",
  "Azul",
  "rojo",
  "Amarillo",
  "morado",
  "rosa",
  "marino",
  "gris",
  "blanco",
  "turquesa",
  "naranja"
]);