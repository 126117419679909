import React, { Component } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setAvatarAccessory } from '../actions/avatar';
import StoreComponent from '../components/StoreComponent';
import { setCount } from './../actions/dashboard';
import { getCount, getAvatar, getAvatarAccessory } from './../selectors/dashboard';
import {getStore} from './../selectors/store';


class StoreContainer extends Component{
  

  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount(){
    
  }

  getStore(){

  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleChangeAvatar = ( value ) => {
    console.log(value);
    this.props.history.push('/home');
  }

  handleBuyAccessory = ( value ) => {
    console.log(value);
    //TODO AXIO

  }

  handleDressAccessory = ( value ) => {
    this.props.setAvatarAccessory(value);
    this.forceUpdate();
  }

  handleSave = ( value ) => {
    console.log(value);
  }

  handleClose = ( ) => {
    console.log("close");
    this.props.history.push('/home')
  }

  render(){
    return (
      <StoreComponent
        name={"Arturo"}
        avatar={this.props.avatar}
        handleBuyAccessory={this.handleBuyAccessory}
        handleDressAccessory={this.handleDressAccessory}
        avatarAccessory={this.props.avatarAccessory}
        store={this.props.store}
        handleClose={this.handleClose}
        handleSave={this.handleSave}
        width={this.state.width}
        height={this.state.height}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  count: getCount(state),
  avatar: getAvatar(state),
  store: getStore(state),
  avatarAccessory: getAvatarAccessory(state)
});

export default withRouter( connect( mapStateToProps, { setCount, setAvatarAccessory } ) (StoreContainer) )