import React, { Component } from 'react';
import { URL_LOGIN } from '../constans';
import LoginComponent from '../components/LoginComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class LoginContainer extends Component {
  
  handleLogin = (values) => {
    const { username, password } = values;
    console.log(values);
    
    // fetch( URL_LOGIN, {
    //   method: 'POST',
    //   headers: new Headers({
    //     'Content-Type':'application/json',
    //     'Access-Control-Allow-Origin':'*',
    //     'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTIONS, PUT, PATCH, DELETE',
    //     'Access-Control-Allow-Headers': 'Origin, Methods, Content-Type'
    //   }),
    //   body: JSON.stringify({
    //     email: values.email,
    //     password: values.password
    //   })
    // })
    // .then( v => v.json())
    // .then( r => {
    //   TO DO 
    //   if(r.errors){
    //     return Promise.reject(r.errors[0]);
    //   }
    //   console.log("entrada");
    // })
    // .catch(err => {
    //   console.log(err);
    //   return Promise.reject(err);
    // })

    this.props.history.push('/home');
  }
  
  render() {
    return (
      <LoginComponent
      onSubmit={this.handleLogin}
      />
    );
  }
}

const mapStateToProps = (state) => ({

});

export default withRouter( connect( mapStateToProps, { 
}) (LoginContainer) );
