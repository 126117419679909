import React from "react";

import { Container, Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormControl, InputLabel, Input, Button, Grid, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import CustomScroll from 'react-custom-scroll';

const StoreComponent = props => {
  const { name, avatar, handleBuyAccessory, 
    handleSave, handleClose,
    handleDressAccessory, avatarAccessory, store, width, height } = props;
  console.log(width);

  if(width < 574){
    return (
      <div>
        <Container className="background" fluid={true}>
          <Row className="title">
            <Col className="" lg={{size:12}} md={{size:12}} xs={{size:12}} md={{size:12}} xs={{size:12}}>
              <h4 className="intro">Bienvenido {name}</h4>
              <img className="logo" alt="logo" src="./../../image/logo.png" />
            </Col>
          </Row>
          <Row>
            <div style={{height:"50px"}}></div>
            <Col xs={{size:12}} lg={{size:8, offset:2}}>
              <Row>
                <Col lg={{size:6}} md={{size:6}} xs={{size:6}}>
                  <div className="avatarDiv">
                    <img className="avatar" src={"./../../image/avatars/monitos_"+ avatar +".png"} />
                    <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/footer/"+ avatarAccessory.head + "_" + avatar + ".png"}  />
                    <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/body_bottom/"+ avatarAccessory.body + "_" + avatar + ".png"}  />
                    <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/body/"+ avatarAccessory.body_bottom + "_" + avatar + ".png"}  />
                    <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/head/"+ avatarAccessory.footer + "_" + avatar + ".png"}  />
                    <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/footer/"+ avatarAccessory.jewelry + "_" + avatar + ".png"}  />
                  </div>
                  <div style={{display:"inline-flex"}}>
                    <Button id="avatarButton" onClick={handleSave}>
                      <img alt="logo" src="./../../image/store/check.png" />
                    </Button>
                    <Button id="avatarButton" onClick={handleClose} style={{marginLeft:"20px"}}>
                      <img alt="logo" src="./../../image/store/cerrar.png" />
                    </Button>
                  </div>
                </Col>
                <Col xl={{size:6}} lg={{size:12}} md={{size:6}} sm={{size:6}} xs={{size:12}}>
                  <div className="order container">
                    <Row>
                      {store.map(result => 
                        <Col xl={{size:6}} lg={{size:12}} md={{size:12}} sm={{size:12}} xs={{size:8}}>
                          <div className="item" style={{marginBottom:"10px"}} >
                            
                            <div>
                              <img className="item_picture" src={"./../../image/store/"+ result.file}/>
                            </div>
                            
                              {result.buyer ?
                              (<div className="button" style={{marginTop:"10px", display:"block", width: "100%"}}>
                                <Button onClick={() => handleDressAccessory(result)} >
                                  <img className="dressButton" src="./../../image/icon/buscar.svg" />
                                </Button> 
                                <Button onClick={() => handleBuyAccessory(result)} >
                                  <img className="buyButton"  src="./../../image/icon/carrito.svg" />
                                </Button>
                              </div>) : (<div className="button" style={{marginTop:"10px", display:"block", width: "100%"}}>
                                <Button onClick={() => handleDressAccessory(result)} >
                                  <img className="dressButton" src="./../../image/icon/buscar.svg" />
                                </Button> 
                              </div>) }
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  if( width > 989 && width < 1200){
    return (
      <div>
        <Container className="background" fluid={true}>
          <Row className="title">
            <Col lg={{size:12}} md={{size:12}} xs={{size:12}} md={{size:12}} xs={{size:12}}>
              <h4 className="intro">Bienvenido {name}</h4>
              <img className="logo" alt="logo" src="./../../image/logo.png" />
            </Col>
          </Row>
          <Row>
            <div style={{height:"50px"}}></div>
            <Col xs={{size:12}} sm={{size:12}} md={{size:12}} lx={{size:8, offset:1}} lg={{size:10, offset:1}}>
              <Row>
                <Col xl={{size:6}} lg={{size:6}} md={{size:6}} sm={{size:6}} xs={{size:12}}>
                  <Row>
                    <CustomScroll heightRelativeToParent="700px">
                      {store.map(result => 
                        <Col class="orden" xl={{size:6}} lg={{size:12}} md={{size:12}} sm={{size:12}} xs={{size:12}}>
                          <div className="item" style={{marginBottom:"10px"}} >
                            
                            <div>
                              <img className="item_picture" src={"./../../image/store/"+ result.file}/>
                            </div>
                            
                              {result.buyer ?
                              (<div className="button" style={{marginTop:"10px", display:"block", width: "100%"}}>
                                <Button onClick={() => handleDressAccessory(result)} >
                                  <img className="dressButton" src="./../../image/icon/buscar.svg" />
                                </Button> 
                                <Button onClick={() => handleBuyAccessory(result)} >
                                  <img className="buyButton"  src="./../../image/icon/carrito.svg" />
                                </Button>
                              </div>) : (<div className="button" style={{marginTop:"10px", display:"block", width: "100%"}}>
                                <Button onClick={() => handleDressAccessory(result)} >
                                  <img className="dressButton" src="./../../image/icon/buscar.svg" />
                                </Button> 
                              </div>) }
                          </div>
                        </Col>
                      )}
                    </CustomScroll>
                  </Row>
                </Col>
                <Col lg={{size:6}} md={{size:6}} xs={{size:6}}>
                  <div className="avatarDiv">
                    <img className="avatar" src={"./../../image/avatars/monitos_"+ avatar +".png"} width="350"/>
                    
                    <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/footer/"+ avatarAccessory.head + "_" + avatar + ".png"}  width="350"/>
                    <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/body_bottom/"+ avatarAccessory.body + "_" + avatar + ".png"}  width="350"/>
                    <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/body/"+ avatarAccessory.body_bottom + "_" + avatar + ".png"}  width="350"/>
                    <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/head/"+ avatarAccessory.footer + "_" + avatar + ".png"}  width="350"/>
                    <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/footer/"+ avatarAccessory.jewelry + "_" + avatar + ".png"}  width="350"/>
                  </div>
                  <div style={{display:"flex"}}>
                    <Button id="avatarButton" onClick={handleSave}>
                      <img alt="logo" src="./../../image/store/check.png" />
                    </Button>
                    <Button id="avatarButton" onClick={handleClose} style={{marginLeft:"20px"}}>
                      <img alt="logo" src="./../../image/store/cerrar.png" />
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  return (
    <div>
      <Container className="background" fluid={true}>
        <Row className="title">
          <Col className="" lg={{size:12}} md={{size:12}} xs={{size:12}} md={{size:12}} xs={{size:12}}>
            <h4 className="intro">Bienvenido {name}</h4>
            <img className="logo" alt="logo" src="./../../image/logo.png" />
          </Col>
        </Row>
        <Row>
          <div style={{height:"50px"}}></div>
          <Col lg={{size:8, offset:2}}>
            <Row>
              <Col xl={{size:6}} lg={{size:12}} md={{size:6}} sm={{size:6}} xs={{size:12}}>
                <Row>
                  <CustomScroll heightRelativeToParent="700px">
                    {store.map(result => 
                      <Col class="orden" xl={{size:6}} lg={{size:12}} md={{size:12}} sm={{size:12}} xs={{size:12}}>
                        <div className="item" style={{marginBottom:"10px"}} >
                          
                          <div>
                            <img className="item_picture" src={"./../../image/store/"+ result.file}/>
                          </div>
                          
                            {result.buyer ?
                            (<div className="button" style={{marginTop:"10px", display:"block", width: "100%"}}>
                              <Button onClick={() => handleDressAccessory(result)} >
                                <img className="dressButton" src="./../../image/icon/buscar.svg" />
                              </Button> 
                              <Button onClick={() => handleBuyAccessory(result)} >
                                <img className="buyButton"  src="./../../image/icon/carrito.svg" />
                              </Button>
                            </div>) : (<div className="button" style={{marginTop:"10px", display:"block", width: "100%"}}>
                              <Button onClick={() => handleDressAccessory(result)} >
                                <img className="dressButton" src="./../../image/icon/buscar.svg" />
                              </Button> 
                            </div>) }
                        </div>
                      </Col>
                    )}
                  </CustomScroll>
                </Row>
              </Col>
              <Col lg={{size:6}} md={{size:6}} xs={{size:6}}>
                <div className="avatarDiv">
                  <img className="avatar" src={"./../../image/avatars/monitos_"+ avatar +".png"} width="350"/>
                  
                  <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/footer/"+ avatarAccessory.head + "_" + avatar + ".png"}  width="350"/>
                  <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/body_bottom/"+ avatarAccessory.body + "_" + avatar + ".png"}  width="350"/>
                  <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/body/"+ avatarAccessory.body_bottom + "_" + avatar + ".png"}  width="350"/>
                  <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/head/"+ avatarAccessory.footer + "_" + avatar + ".png"}  width="350"/>
                  <img className="avatar_accesory" src={"./../../image/accesorios/"+ avatar +"/footer/"+ avatarAccessory.jewelry + "_" + avatar + ".png"}  width="350"/>
                </div>
                <div style={{display:"flex"}}>
                  <Button id="avatarButton" onClick={handleSave}>
                    <img alt="logo" src="./../../image/store/check.png" />
                  </Button>
                  <Button id="avatarButton" onClick={handleClose} style={{marginLeft:"20px"}}>
                    <img alt="logo" src="./../../image/store/cerrar.png" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default StoreComponent;