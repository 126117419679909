//Count
export const FETCH_COUNT = "FETCH_COUNT";
export const SET_COUNT = "SET_COUNT";
export const SET_FETCH_COUNT =  "SET_FETCH_COUNT";
export const SET_AVATAR = "SET_AVATAR";
export const SET_AVATAR_ACCESSORY = "SET_AVATAR_ACCESSORY";
export const SET_AVATARS = "SET_AVATARS";
export const SET_DRESS_ACCESSORY = "SET_DRESS_ACCESSORY";
export const SET_CUSTOM_AVATAR =  "SET_CUSTOM_AVATAR";


//Login
export const LOGIN = "LOGIN";


//URL
export const URL_BASE = "http://localhost:5000/api";
export const URL_LOGIN = `${URL_BASE}/users/login`;

export const URL_GET_COUNT = `${URL_BASE}/users/count`;
export const URL_GET_AVATAR = `${URL_BASE}/users/avatar`;