import { handleActions } from 'redux-actions';

import { SET_DRESS_ACCESSORY } from '../constans';

export const store = handleActions({
  [SET_DRESS_ACCESSORY]: (state, action) => {
    console.log(action);
    console.log(state);
    return action.payload;
  }
}, [
   {
      file:"camisa_luna.png",
      category:"body",
      name:"camisaLuna",
      buyer:false
   },
   {
     file:"camisa_platano.png",
     category:"body",
     name:"camisaPlatano",
     buyer:true
   },
   {
     file:"collar_jade.png",
     category:"jewelry",
     name:"collarJade",
     buyer:true
   },
   { 
     file:"joyeria_amarillo.png",
     category:"jewelry",
     name:"joyeriaAmarillo",
     buyer:true
   },
   {
     file:"lentes_negros.png",
     category:"head",
     name:"lentesArriba",
     buyer:false
   },
   {
     file:"lentes_negros_2.png",
     category:"head",
     name:"lentesOjos",
     buyer:false
   },
   {
     file:"mono.png",
     category:"head",
     name:"moño",
     buyer:true
   },
   {
     file:"mono_azul.png",
     category:"head",
     name:"pañueloAzul",
     buyer:false
   },
   {
     file:"munequeras.png",
     category:"jewelry",
     name:"muñequeras",
     buyer:false
   },
   {
     file:"panuelo_cuello.png",
     category:"jewelry",
     name:"pañueloCuello",
     buyer:false
   },
   {
     file:"short.png",
     category:"body_bottom",
     name:"short",
     buyer:false
   },
   {
     file:"sombrero_cafe.png",
     category:"head",
     name:"SombreroCafe",
     buyer:false
   },

]);