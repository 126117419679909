import { handleActions } from 'redux-actions';

import { SET_COUNT } from './../constans';

export const count = handleActions({
  [SET_COUNT]: (state, action) => {
    const data = [];
    action.payload.map((result, index) =>{
      data.push({
        "banana_amarilla": result.banana_amarilla,
        "banana_azul":     result.banana_azul,
        "banana_naranja":  result.banana_naranja,
        "banana_negra":    result.banana_negra,
        "banana_rosa":     result.banana_rosa,
        "banana_verde":    result.banana_verde
      })
    })

    return data;
  }
}, 
  {
    "banana_amarilla": 10,
    "banana_azul":     15,
    "banana_naranja":  41,
    "banana_negra":    62,
    "banana_rosa":     7,
    "banana_verde":    96,
    "totem_amarilla":  87,
    "totem_azul":      57,
    "totem_naranja":   81,
    "totem_negra":     97,
    "totem_rosa":      7,
    "totem_verde":     71
  }
)