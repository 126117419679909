import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import { count } from './dashboard';
import { avatar, avatars, avatarAccessory } from './avatar';
import { store } from './store';

export default combineReducers({
  form: reduxForm,
  count,
  avatar,
  avatars,
  avatarAccessory,
  store,
})